import React from 'react';
import {string, bool, objectOf, arrayOf, any} from "prop-types";
import Title from "../Title";
import Image from "../ImageQueries/GeneralImages";
import { StaticImage } from "gatsby-plugin-image"
const IntlOffices = (props) => {
    const {className, otherOffices, primaryOffice} = props
    return (
        <section className='my-32 mx-[16px] max-w-[1440px] lg:mx-0 md:mx-8  lg:px-[160px] 2xl:mx-auto'>
            <Title level={3} symbol='.' withBlueSymbol className='text-center text-[32px] leading-[130%]'>SpotOn Offices</Title>
            <div className='grid md:gap-[60px] xl:gap-[120px] gap-4 md:grid-cols-2 grid-cols-1'>
                <Image
                    imageName={primaryOffice.hqImg}
                    alt='Golden'
                />
                <div className='self-center'>
                    <Image imageName={primaryOffice.hqLogo} alt='icon' className='max-w-[100px] max-h-[100px] mb-8'/>
                    <Title level={2} className='text-[42px] leading-[110%]' style={{ margin: 0}}>Headquarters</Title>
                    <Title level={2} className='text-[42px] leading-[110%]' withBlueSymbol symbol='.' style={{ margin: 0}}>{primaryOffice.title}</Title>
                    <span className='flex'><StaticImage src='../../assets/map-marker-alt.png'alt='icon' className='max-w-[16px] max-h-[21px] object-scale-down flex-1 translate-y-[18px]' /> <p className='font-sans text-black-300 text-[16px] leading-[150%] inline max-w-[80%] ml-2'>{primaryOffice.address}</p></span>
                    <span className='flex'><StaticImage src='../../assets/phone.png' alt='icon' className='max-w-[20px] max-h-[20px] object-scale-down flex-1 translate-y-[14px]' /> <p className='font-sans text-black-300 text-[16px] leading-[150%] inline ml-2'><a className='text-primary no-underline' href={`tel:+001${primaryOffice.phone}`}>{primaryOffice.phone}</a></p></span>
                </div>
            </div>
            <div className='bg-[#E8F0FF] mx-0  px-[22px] pt-[32px] lg:pt-[16px] pb-[30px] lg:pb-[34px] lg:px-[54px] rounded-[16px] mt-12'>
                <Title level={3} symbol='.' withBlueSymbol className='text-[24px] leading-[130%]' >Additional Offices</Title>
                <div className='grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12'>
                    {otherOffices.map((office, idx) => {
                        return (
                            <div key={`${office.location}__office`} className='grid grid-cols-[100px,2fr] md:grid-cols-[100px,2fr] xl:grid-cols-[100px,3fr]'>
                                <Image imageName={office.icon} className='max-h-[75px] max-w-[75px] '/>
                                <div >
                                    <p className='font-sans text-black font-bold my-0 text-[24px] leading-[130%]'>{office.location}</p>
                                    <p className='font-sans text-black-300 mb-0 pt-0 mt-[6px] text-[16px] leading-[110%]'>{office.address}</p>
                                    <p className='font-sans text-primary mt-1 text-[16px] leading-[110%]'><a className='text-primary no-underline' href={`tel:${office.phoneText}`}>{office.phoneText}</a></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};
IntlOffices.propTypes = {
    className: string,
    otherOffices: arrayOf(any),
    primaryOffice: objectOf(any)
};

IntlOffices.defaultProps = {
    className: '',
    otherOffices: [],
    primaryOffice: ''
};

export default IntlOffices;
