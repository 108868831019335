import React from 'react';
import {string, bool, number, objectOf, any} from "prop-types";
import Title from "../Title";
import CtaPrimary from "../Buttons/Button";
import Carousel from "nuka-carousel";
import Image from "../ImageQueries/GeneralImages";

const SplitFeatures = (props) => {
    const {className, office, subtext, url, img, idx, address} = props
    return (
        <>
            {idx % 2 === 0 ? (
                <div className='grid xl:grid-cols-2 md:grid-cols-1 min-h-[450px] xxl:max-h-[800px] max-h-[2000px] bg-black'>
                    <div className='bg-black lg:px-40 lg:pb-40 lg:pt-32 pt-12 pb-24 md:px-8 px-[16px] 2xl:justify-self-end self-center xl:order-first order-last'>
                        <Title level={2} withBlueSymbol symbol='.' className='text-white mb-4  text-[42px] leading-[140%]' style={{ marginBottom: 0}}>{office}</Title>
                        <p className='font-sans text-black-500 text-[16px] leading-[160%] mt-[18px] lg:max-w-[450px]'>{subtext}</p>
                        <p className='font-sans text-black-500 text-[16px] leading-[200%] whitespace-pre-line'><span className='font-bold'>Address: <br/> </span>
                            {address.split('\\n').map((item) => (
                                <div key={item}>
                                    {item}
                                    <br/>
                                </div>
                            ))}
                        </p>
                        <CtaPrimary title='See on map' openInNewTab target={url} className='mt-[40px]'/>
                    </div>
                    <Image className='border-box max-h-[2000px] w-full xl:order-last order-1' imageName={img} />
                </div>
            ): (
                <div className='grid bg-black xl:grid-cols-2 md:grid-cols-1 min-h-[450px] xxl:max-h-[800px] max-h-[2000px]'>
                    <Image className='border-box max-h-[2000px] w-full' imageName={img} />
                    <div className='bg-black lg:px-40 lg:pb-40 lg:pt-32 pt-12 pb-24 md:px-8  px-[16px] self-center'>
                        <Title level={2} withBlueSymbol symbol='.' className='text-white mb-4  text-[42px] leading-[140%]' style={{marginBottom: 0}}>{office}</Title>
                        <p className='font-sans text-black-500  text-[16px] leading-[160%] lg:max-w-[450px] mt-[18px]'>{subtext}</p>
                        <p className='font-sans text-black-500 text-[16px] leading-[200%] whitespace-pre-line'><span className='font-bold'>Address: <br/> </span>
                            {address.split('\\n').map((item) => (
                                <div  key={item}>
                                    {item}
                                    <br/>
                                </div>
                            ))}</p>
                        <CtaPrimary title='See on map' openInNewTab target={url} className='mt-[40px]'/>
                    </div>
                </div>
                )}
        </>
    );
};
SplitFeatures.propTypes = {
    className: string,
    office: string,
    subtext: string,
    url: string,
    img: objectOf(any),
    idx: number,
    address: string,
};

SplitFeatures.defaultProps = {
    className: '',
};

export default SplitFeatures;
