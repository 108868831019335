import * as React from 'react';
import Layout from '../Components/Layout';
import HeadingDirective from '../Components/HeadingDirective';
import Hero from '../Components/Hero';
import ContactForm from '../Components/ContactForm';
import SplitFeatures from '../Components/LargeFeature/SplitFeatures';
import IntlOffices from '../Components/Offices/IntlOffices';
import { graphql } from 'gatsby';
import SEO from '../Components/SEO';

const ContactUs = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout prefootImg="ContactUsPre.png">
            <SEO
                title={frontmatter.seo.title}
                description={frontmatter.seo.description}
                image={`https://pl.spoton.com/assets/${frontmatter.seo.image}`}
            />
            <HeadingDirective
                showCircle
                imgClass="top-[257px] w-[207px]"
                {...frontmatter.directive}
            />
            <Hero {...frontmatter.hero} />
            <ContactForm />
            <section>
                {frontmatter.splitList.map((office, idx) => (
                    <SplitFeatures {...office} key={office.office} idx={idx} />
                ))}
            </section>
            <IntlOffices
                primaryOffice={frontmatter.primaryOffice}
                otherOffices={frontmatter.otherOffices}
            />
        </Layout>
    );
};

export default ContactUs;

export const pageQuery = graphql`
    query ContactUs {
        markdownRemark(
            frontmatter: { templateKey: { eq: "contact-us-page" } }
        ) {
            frontmatter {
                directive {
                    directive
                    symbol
                    subtext
                }
                seo {
                    title
                    description
                    image
                }
                primaryOffice {
                    title
                    phone
                    address
                    hqImg {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                    hqLogo {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                hero {
                    heroTitle
                    heroSubtext
                    heroImage {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                splitList {
                    office
                    subtext
                    address
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                    url
                }

                otherOffices {
                    location
                    icon {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                    address
                    phone
                    phoneText
                }
            }
        }
    }
`;
